import "../../assets/scss/app.scss";
import Swiper from 'swiper/bundle';



// fadeIn
function fadeIn(elm, ms) {
  elm.style.opacity = 0; // 透過度０
  elm.style.display = "block";
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 1;
  }, 1); // 0.001秒後に transition開始(透過度１にする）
}
// fadeOut
function fadeOut(elm, ms) {
  elm.style.opacity = 1; // 透過度1
  elm.style.transition = "opacity " + ms + "ms";
  setTimeout(function () {
    elm.style.opacity = 0;
  }, 5); // 0.001秒後に transition開始（透過度0にする）
  setTimeout(function () {
    elm.style.display = "none";
  }, ms + 10); // 1.01秒後に完全に消す。
}



class Main {
  constructor() {
    const option = {
      loop: true,
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        reverseDirection: false
      },
      effect: 'slider',
      speed: 600,
      loop: true,
      slidesPerView: 1.5,
      centeredSlides: true,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    }

    const fvSwiper = new Swiper('.sliderWrap', option);


    const menu = document.querySelector("#menu");
    const menuBtn = document.querySelector("#menuBtn");
    let menuFlg = false;
    menuBtn.addEventListener("click", () => {
      if (menuFlg === false) {
        menuFlg = true;
        menuBtn.classList.add("act");
        fadeIn(menu, 500);
      } else {
        menuFlg = false;
        menuBtn.classList.remove("act");
        fadeOut(menu, 500);

      }
    })

  }
}

window.addEventListener("DOMContentLoaded", () => {
  new Main();
});